import GlobalStyle from './GlobalStyle';
import colors, { webColors } from './colors';
import { GLOBAL_STYLE_VARIABLES } from './constants';
import depths from './depths';
import durations from './durations';
import { useMatchMedia } from './hooks';
import mediaQueries from './mediaQueries';
import spacings from './spacings';
import typography, { webTypography } from './typography';
import utils from './utils';

const theme = {
  colors,
  depths,
  durations,
  mq: mediaQueries,
  spacings,
  tp: typography,
  utils,
  web: {
    colors: webColors,
    tp: webTypography,
  },
};

export { theme as default, GlobalStyle, GLOBAL_STYLE_VARIABLES, useMatchMedia };
