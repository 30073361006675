import { LinkResolverFunction } from '@prismicio/helpers';

import type { FilledContentRelationshipField } from '@prismicio/types';

export const linkResolver: LinkResolverFunction = ({
  type,
  uid,
}: FilledContentRelationshipField) => {
  switch (type) {
    case 'pmco_portfolio_archive': {
      return '/portfolios';
    }
    case 'pmco_portfolio': {
      return `/portfolios/${uid}`;
    }
    case 'pmco_ai': {
      return `/ai`;
    }
    case 'pmco_product_archive': {
      return '/ai/products';
    }
    case 'pmco_product': {
      return `/ai/products/${uid}`;
    }
    case 'pmco_contact': {
      return `/contact`;
    }
    case 'web_landing': {
      return '/web';
    }
    case 'pmco_landing':
    default: {
      return '/';
    }
  }
};
