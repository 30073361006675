import React from 'react';
import { GatsbySSR } from 'gatsby';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import PreviewProvider from './PreviewProvider';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);

const Root: GatsbySSR['wrapRootElement'] = ({ element }) => {
  return <PreviewProvider>{element}</PreviewProvider>;
};

export default Root;
