import { css } from '@emotion/react';

import { BASE_REM } from './constants';

const utils = {
  rem: (unitPx: number): string => `${unitPx / BASE_REM}rem`,
  alphaHex: (hex: string, alpha: number) => {
    const decimalAlpha = alpha / 100;

    const hexAlpha = Math.round(decimalAlpha * 255)
      .toString(16)
      .padStart(2, '0');

    return `${hex}${hexAlpha}`;
  },
  composeTemplateAreas: (rows: string[][]) =>
    rows.reduce((acm, columns) => `${acm} '${columns.join(' ')}'`, ``),
  hideScroll: css`
    ${'scrollbar-width'}: none;
    &::-webkit-scrollbar {
      display: none;
    }
  `,
};

export default utils;
