import { useCallback, useEffect, useMemo, useState } from 'react';

export const useMatchMedia = (...conditionalOrMedias: string[][]) => {
  const mediaSets = useMemo(
    () =>
      conditionalOrMedias.map(conditionalAndMedias => {
        const medias = conditionalAndMedias.map(
          mediaQuery => `(${mediaQuery.split('(')[1]}`
        );

        return medias.join(' and ');
      }),
    [conditionalOrMedias]
  );

  const calculateMatch = useCallback(() => {
    if (typeof window !== 'undefined') {
      return mediaSets.some(media => window.matchMedia(media).matches);
    }

    return false;
  }, [mediaSets]);

  const [match, setMatch] = useState(calculateMatch());

  useEffect(() => {
    const resizeListener = () => {
      setMatch(calculateMatch());
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [calculateMatch]);

  return match;
};
