import React from 'react';
import { Global, css } from '@emotion/react';
import normalize from 'emotion-normalize';

import composeFontSize from 'utils/composeFontSize';

import colors from './colors';
import { BASE_REM, GLOBAL_STYLE_VARIABLES } from './constants';
import mq from './mediaQueries';
import { FONT_FAMILIES } from './typography';
import utils from './utils';

const fontFamilies = Object.keys(FONT_FAMILIES).join(',');

const globalStyles = css`
  ${normalize}

  html,
  body {
    margin: 0;
    padding: 0;
  }

  html {
    ${GLOBAL_STYLE_VARIABLES.margin}: 1fr;
    ${GLOBAL_STYLE_VARIABLES.container}: ${utils.rem(
      mq.xl.container as number
    )};

    letter-spacing: 0.02em;
    font-family: ${fontFamilies}, sans-serif;
    font-size: ${BASE_REM}px;
    font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off, 'liga' off;
    scroll-behavior: smooth;

    &::after {
      position: fixed;
      inset: -50%;
      z-index: -1;
      background: ${colors.neutral.black};
      content: '';
    }

    /* ----- Desktop ----- */
    ${mq.xl.down} {
      color: black;
      font-size: ${composeFontSize({
        ...mq.lg.fluid,
        widthMax: mq.xl.width,
        widthMin: mq.lg.width,
      })};
    }

    /* ----- Tablet ----- */
    ${mq.lg.down} {
      ${GLOBAL_STYLE_VARIABLES.margin}: ${utils.rem(mq.lg.margin as number)};
      ${GLOBAL_STYLE_VARIABLES.container}: 1fr;

      font-size: ${composeFontSize({
        ...mq.lg.fluid,
        widthMax: mq.lg.width,
        widthMin: mq.md.width,
      })};
    }

    /* ----- Mobile ----- */
    ${mq.md.down} {
      ${GLOBAL_STYLE_VARIABLES.margin}: 1fr;
      ${GLOBAL_STYLE_VARIABLES.container}: ${utils.rem(
        mq.md.container as number
      )};

      @media (orientation: landscape) {
        font-size: ${BASE_REM}px;
      }
      @media (orientation: portrait) {
        font-size: ${composeFontSize({
          ...mq.md.fluid,
          widthMax: mq.md.width,
          widthMin: mq.sm.width,
        })};
      }
    }

    ${mq.sm.down} {
      ${GLOBAL_STYLE_VARIABLES.margin}: ${utils.rem(mq.sm.margin as number)};
      ${GLOBAL_STYLE_VARIABLES.container}: 1fr;

      font-size: ${BASE_REM}px;
    }

    ${mq.xs.down} {
      font-size: ${composeFontSize({
        ...mq.xs.fluid,
        widthMax: mq.xs.width,
        widthMin: mq.tn.width,
      })};
    }
  }

  body {
    ${GLOBAL_STYLE_VARIABLES.scrollbarWidth}: 0;
    ${GLOBAL_STYLE_VARIABLES.scrollbarCompensation}: calc(0 * 1px);
    ${GLOBAL_STYLE_VARIABLES.headerHeight}: 12rem;

    min-height: 100vh;

    &[data-scroll-lock] {
      ${GLOBAL_STYLE_VARIABLES.scrollbarCompensation}: var(${GLOBAL_STYLE_VARIABLES.scrollbarWidth});

      padding-right: var(${GLOBAL_STYLE_VARIABLES.scrollbarCompensation});
      overflow: hidden;
    }

    ${mq.md.down} {
      ${GLOBAL_STYLE_VARIABLES.headerHeight}: 10.5rem;
    }
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    color: ${colors.neutral.black};

    /* Prevents highlight for mobile devices (except Safari) */
    -webkit-tap-highlight-color: #0000;

    /* 
      Prevents highlight for mobile Safari devices
      Narrowed to focused pseudo states
     */
    &:focus,
    &:visited,
    &:active {
      outline: none;
    }
  }
`;

const GlobalStyle: React.FC = () => <Global styles={globalStyles} />;

export default GlobalStyle;
