import React, { PropsWithChildren } from 'react';

import { useAnimationState } from './useAnimationState';

export const AnimationStateProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  useAnimationState();

  return <>{children}</>;
};
