import { useCallback, useLayoutEffect } from 'react';

import { GLOBAL_STYLE_VARIABLES } from 'theme';

export const useScrollLock = () => {
  const syncScrollWidth = useCallback(() => {
    const scrollbarWidth = window.innerWidth - document.body.offsetWidth;

    document.body.style.setProperty(
      GLOBAL_STYLE_VARIABLES.scrollbarWidth,
      `${scrollbarWidth}px`
    );
  }, []);

  const lockScroll = useCallback(() => {
    document.body.dataset.scrollLock = 'true';
  }, []);

  const unlockScroll = useCallback(() => {
    delete document.body.dataset.scrollLock;
  }, []);

  useLayoutEffect(() => {
    syncScrollWidth();

    return () => {
      unlockScroll();
    };
  }, []);

  return [lockScroll, unlockScroll, syncScrollWidth];
};
