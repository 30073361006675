import React from 'react';
import { GatsbyBrowser } from 'gatsby';

import { AnimationStateProvider } from 'animations';

import StateProvider from './StateProvider';
import ThemeProvider from './ThemeProvider';
import { useQueryParams } from './hooks';

const Page: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props: pageProps,
}) => {
  useQueryParams(pageProps);

  return (
    <StateProvider>
      <AnimationStateProvider>
        <ThemeProvider>{element}</ThemeProvider>
      </AnimationStateProvider>
    </StateProvider>
  );
};

export default Page;
