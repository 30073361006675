import { useLayoutEffect, useMemo, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useRecoilValue } from 'recoil';

import { animationRefreshState } from 'animations/state/atoms';
import mediaQueries from 'theme/mediaQueries';
import { getWindow } from 'utils/helpers';
import { PickByValueType } from 'utils/types';

export type AnimationEnabledBreakpoints =
  | keyof PickByValueType<typeof mediaQueries, string>
  | number;

export const useAnimationEnabled = (
  breakpoint: AnimationEnabledBreakpoints = 0
) => {
  const { mq } = useTheme();

  const refreshed = useRecoilValue(animationRefreshState);

  const media = useMemo(() => {
    const query = mq[breakpoint || 'md'].down;

    const [matchMedia] = query.split('@media ').slice(1);

    return matchMedia;
  }, [breakpoint]);

  const [enabled, setEnabled] = useState(
    getWindow()?.matchMedia(media).matches ?? false
  );

  useLayoutEffect(() => {
    setEnabled(window.matchMedia(media).matches);
  }, [refreshed, media]);

  return enabled;
};
