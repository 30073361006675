exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ai-index-tsx": () => import("./../../../src/pages/ai/index.tsx" /* webpackChunkName: "component---src-pages-ai-index-tsx" */),
  "component---src-pages-ai-products-tsx": () => import("./../../../src/pages/ai/products.tsx" /* webpackChunkName: "component---src-pages-ai-products-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolios-tsx": () => import("./../../../src/pages/portfolios.tsx" /* webpackChunkName: "component---src-pages-portfolios-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-web-tsx": () => import("./../../../src/pages/web.tsx" /* webpackChunkName: "component---src-pages-web-tsx" */),
  "component---src-templates-pmco-portfolio-page-tsx": () => import("./../../../src/templates/PmcoPortfolio/Page.tsx" /* webpackChunkName: "component---src-templates-pmco-portfolio-page-tsx" */),
  "component---src-templates-pmco-product-page-tsx": () => import("./../../../src/templates/PmcoProduct/Page.tsx" /* webpackChunkName: "component---src-templates-pmco-product-page-tsx" */)
}

