import { useLayoutEffect, EffectCallback, DependencyList } from 'react';
import { gsap } from 'gsap';
import { useRecoilValue } from 'recoil';

import {
  animationRefreshState,
  animationSuspendedState,
} from 'animations/state/atoms';

import {
  AnimationEnabledBreakpoints,
  useAnimationEnabled,
} from './useAnimationEnabled';

export const useAnimationEffect = (
  callback: (state: {
    suspended: boolean;
    enabled: boolean;
  }) => ReturnType<EffectCallback>,
  deps: DependencyList | undefined = [],
  enabledBreakpoint: AnimationEnabledBreakpoints = 0
) => {
  const refreshed = useRecoilValue(animationRefreshState);
  const suspended = useRecoilValue(animationSuspendedState);
  const enabled = useAnimationEnabled(enabledBreakpoint);

  useLayoutEffect(() => {
    const context = gsap.context(() => {
      callback({ suspended, enabled });
    });

    return () => context.revert();
  }, [...deps, callback, refreshed, suspended, enabled]);
};
