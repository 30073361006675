import { useCallback, useLayoutEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';

import { getWindow } from 'utils/helpers';
import { useDebounce } from 'utils/hooks';

import { animationRefreshState } from './atoms';

export const useAnimationState = () => {
  const setAnimationRefreshState = useSetRecoilState(animationRefreshState);

  const screenWidth = useRef<number>(getWindow()?.innerWidth ?? 0);

  const handleResize = useCallback(() => {
    const newScreenWidth = getWindow()?.innerWidth ?? 0;

    if (screenWidth.current !== newScreenWidth) {
      setAnimationRefreshState(token => (token ? 0 : 1));
      screenWidth.current = newScreenWidth;
    }
  }, [screenWidth]);

  const debouncedHandleResize = useDebounce(handleResize, 400);

  useLayoutEffect(() => {
    screenWidth.current = getWindow()?.innerWidth ?? 0;
    window.addEventListener('resize', debouncedHandleResize);

    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, []);
};
